/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */

export function IconX() {
  return (
    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4666 0.09375H25.6357L16.5275 10.5038L27.2425 24.6695H18.8528L12.2816 16.0781L4.76266 24.6695H0.591085L10.3332 13.5348L0.0541992 0.09375H8.65698L14.5967 7.94665L21.4666 0.09375ZM20.0034 22.1741H22.3135L7.40172 2.45806H4.92272L20.0034 22.1741Z"
        fill="white"
      />
    </svg>
  )
}

export function IconLinkedin() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8485_44301)">
        <path
          d="M19.7634 0.09375H5.42757C2.60033 0.09375 0.307617 2.38646 0.307617 5.2137V19.5496C0.307617 22.3768 2.60033 24.6695 5.42757 24.6695H19.7634C22.5917 24.6695 24.8834 22.3768 24.8834 19.5496V5.2137C24.8834 2.38646 22.5917 0.09375 19.7634 0.09375ZM8.49954 19.5496H5.42757V8.28567H8.49954V19.5496ZM6.96355 6.98725C5.97438 6.98725 5.17157 6.1783 5.17157 5.18093C5.17157 4.18357 5.97438 3.37461 6.96355 3.37461C7.95273 3.37461 8.75553 4.18357 8.75553 5.18093C8.75553 6.1783 7.95375 6.98725 6.96355 6.98725ZM20.7874 19.5496H17.7154V13.8111C17.7154 10.3623 13.6195 10.6234 13.6195 13.8111V19.5496H10.5475V8.28567H13.6195V10.093C15.049 7.44497 20.7874 7.24939 20.7874 12.6284V19.5496Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8485_44301">
          <rect
            width="24.5758"
            height="24.5758"
            fill="white"
            transform="translate(0.307617 0.09375)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
