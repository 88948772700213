import { peerClubUrl } from 'constants/index'

export default {
  peerClub: peerClubUrl,
  peerClubSingUP: `${peerClubUrl}/signup`,
  peerClubProfileTrade: `${peerClubUrl}/profile/trade`,
  privacyPolicy: 'https://support.peer.inc/hc/en-us/articles/17261918037787-privacy-policy',
  termsService: 'https://support.peer.inc/hc/en-us/articles/17262293261979-terms-of-service',
  faq: 'https://support.peer.inc/hc/en-us/',
  googlePlay: 'https://play.google.com/store/apps/details?id=inc.peer.app&hl=en&gl=US',
  appStore: 'https://apps.apple.com/us/app/peer/id1669571704',
}
